import React, { useState } from "react"
import styles from "./accordion.module.scss"
import AnimateHeight from "react-animate-height"

const data = [
  {
    name: "about",
    title: "Что такое CRM для репетитора?",
    content: `CRM для репетитора — это система для учета учеников, посещений и абонементов. Она содержит все необходимые инструменты для управления рабочим процессом: расписание занятий, профили учеников, отчеты, онлайн-запись учеников и автоматические уведомления.`,
  },
  {
    name: "use",
    title: "Как внедрить CRM в работу репетитора?",
    content: `Мы рекомендуем использовать подробный чек-лист для заполнения системы, видео- и текстовые инструкции из базы знаний, а также пройти бесплатное онлайн-обучение работе с CRM. `,
  },
]
const descr = "<p>Нужна ли CRM репетитору? Мы в Параплан CRM уверены, что да! Контролировать посещения и оплаты, хранить данные учеников под надежной защитой, общаться с подопечными и их родителями в мессенджерах - в этом репетитору заботливо помогает CRM-система. Важно следить за всеми рабочими процессами и автоматизировать рутинные задачи. Мы расскажем, как быстро подобрать CRM для репетитора.</p>" +
"<p>Обратите внимание на специализированные системы для частных педагогов. Благодаря таким функциям, как учет посещений и оплат, расписание, мессенджеры, рассылки, абонементы и отчетность, рабочий процесс станет эффективным и организованным. Вам больше не придется работать с множеством разрозненных документов, таблиц или бумажных журналов.</p>" +
"<p>Перед покупкой тщательно протестируйте CRM в рамках бесплатного пробного периода. Изучите особенности оформления абонементов, правила списания занятий, виды и удобство расписания, настройки автоматических действий, интеграцию, отчетность и аналитику.</p>" +
"<p>Сравните стоимость подписки у разных CRM для репетитора. Держите подсказку: чем дольше период подписки на систему, тем выгоднее ее цена.</p>"

const TutorAccordionComponent = () => {
  const [tab, setTab] = useState(null)
  return <div className={`container ${styles.tabs}`}>
    <div className={styles.descr}>
      <h2 className={styles.heading}>Советы по внедрению CRM-системы репетитору</h2>
      <div className={styles.content} dangerouslySetInnerHTML={{__html: descr }}></div>
    </div>
    <h2 className={styles.heading}>FAQs</h2>
    {data.map(t =>
      <div key={t.name}
           className={`${styles.tab} ${t.name === (tab && tab.name) ? styles.open : ""}`}
           onClick={() => setTab(t !== tab ? t : null)}>
        <div className={styles.info}>
          <div className={styles.title}>{t.title}</div>
          <AnimateHeight duration={300} height={t.name === (tab && tab.name) ? "auto" : 0}>
            <div className={styles.content}>{t.content}</div>
          </AnimateHeight>
        </div>
        <div className={styles.chevron}>
          <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.05287 11.2318L0.435026 2.66346C0.367169 1.27492 1.01181 0.631447 2.33502 0.733047L9.90108 8.28535L17.4671 0.733047C18.7903 0.631447 19.435 1.27492 19.3671 2.66346L10.7832 11.2318C10.1386 11.8414 9.66358 11.8414 9.05287 11.2318Z"
              fill="currentColor"/>
          </svg>
        </div>
      </div>,
    )}
  </div>
}

export default TutorAccordionComponent
